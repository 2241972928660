import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="body">
      <header className="body">
        <img src={logo} className="logo" alt="logo" />
        <p className='text'>
        coming soon... 
        </p>
      </header>
    </div>
  );
}

export default App;
